
<template>
  <div class="page">
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
      <div class="list" v-if="openVillageDate.length > 0">
        <div class="item" v-for="item in openVillageDate" :key="item.id" @click="navToFun(item.id, item.typeName)">
          <div class="top">
            <span class="itemTitle">{{ item.title }}</span>
          </div>
          <div class="bottom">
            <div class="content">{{ item.openDesc }}</div>
            <div class="name">{{ item.createTime }}</div>
          </div>
          <div class="arrow">
            <van-icon name="arrow" size="14" />
          </div>
        </div>
      </div>
      <van-empty description="暂无数据" v-else />
    <!-- </van-pull-refresh> -->
    <!-- <div class="addBtn" @click="addThereService()">
      <van-button round type="info">
        <van-icon name="plus" />
        <div>添加</div>
      </van-button>
    </div> -->
    <div class="addBtn" @click="addThereService()">
      <img src="@/assets/img/add.png" alt="">
    </div>
  </div>
</template>


<script>
import topBar from "@/components/topBar/topBar";
import { Toast } from 'vant';

export default {
  components: {
    topBar,
  },
  data() {
    return {
      openVillageDate: [],
      isLoading: false,
    };
  },
  methods: {
    onRefresh() {
      this.getDataList(this.$route.query.type)
      Toast('刷新成功');
      this.isLoading = false;
    },
    getDataList(type) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqaffairsopen/list"),
        method: "post",
        params: this.$http.adornParams({
          type: type,
          page: 0,
          limit: 1000,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.openVillageDate = data.page.list;
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    addThereService() {
      this.$router.push({
        name: "gridAddOpenVillage",
        query: { title: this.$route.query.title, type: this.$route.query.type },
      });
    },
    navToFun(userId, title) {
      this.$router.push({
        name: "gridOpenLabour",
        query: { userId, title },
      });
    },
  },
  mounted() {
    this.getDataList(this.$route.query.type);
  },
};
</script>


<style scoped lang="scss" >
.page {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 0 100px 0;
  box-sizing: border-box;
  background: #f5f5f5;
  font-size: 24px;
  .list {
    padding: 30px;
    .item {
      position: relative;
      width: 100%;
      background: #fff;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 20px;
      .top {
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .itemTitle {
          font-size: 30px;
        }
      }
      .bottom {
        padding: 30px 30px 0 0;
        .content {
          color: #969799;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 32px;
          font-size: 26px;
        }
        .name {
          color: #969799;
          font-size: 26px;
          margin-top: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
        }
      }
      .arrow {
        position: absolute;
        right: 10px;
        top: 50%;
      }
    }
  }
}
.addBtn {
  position: fixed;
  right: 30px;
}
.van-button {
  width: 150px;
  height: 150px;
}
</style>
